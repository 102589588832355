<template>
    <el-dialog :title="title" :visible="visible" width="25%" :before-close="handleClose">
        <span class="w-full h-full block" v-loading="$waiting.is('updating')">
            <el-form label-position="top" ref="form" :model="form" :rules="rules" label-width="100px">
                <el-radio-group v-model="form.isInactive" class="mb-4">
                    <el-radio-button label="false">Aktiva</el-radio-button>
                    <el-radio-button label="true">Inaktiv</el-radio-button>
                </el-radio-group>
                <el-form-item label="Namn" prop="name">
                    <div class="flex">
                        <Icons @input="setIcon" :value="form.icon" :icons="icons" />
                        <el-input size="large" v-model="form.name" />
                    </div>
                </el-form-item>
                <el-form-item label="Engelska Namn" prop="nameEn">
                    <el-input size="large" v-model="form.nameEn" />
                </el-form-item>
                <el-form-item label="Typ" prop="type">
                    <el-select v-model="form.type" class="w-full" size="large">
                        <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose()">Avbryt</el-button>
                <el-button type="primary" @click="onSave()">Spara</el-button>
            </span>
        </span>
    </el-dialog>
</template>
<script>
import Api from "../../accountCategories.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },

        accountGroup: {
            type: Object,
        },

        icons: {
            type: Array,
        },
    },

    watch: {
        accountGroup: {
            async handler() {
                this.form = { ...this.accountGroup };
            },
        },
    },

    data() {
        return {
            rules: {
                name: [{ required: true, message: "Namn saknas", trigger: "blur" }],
                nameEn: [{ required: true, message: "Namn engelska saknas", trigger: "blur" }],
                type: [{ required: true, message: "Typ saknas", trigger: "blur" }],
            },
            form: {
                id: 0,
                name: "",
                nameEn: "",
                type: "",
                icon: {},
                isInactive: false,
            },
            typeOptions: [
                {
                    value: 1,
                    label: "Enskildfirma",
                },
                {
                    value: 2,
                    label: "Aktiebolag  ",
                },
            ],
        };
    },

    components: {
        Icons: () => import(/* webpackChunkName: "Icons" */ "../Icons.vue"),
    },

    methods: {
        handleClose() {
            this.$emit("close");
        },

        setIcon(i) {
            this.form.icon = i;
        },

        async onSave() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.$waiting.start("updating");
            await Api.updateAccountGroupCategories(this.form);
            this.$waiting.end("updating");
            this.$emit("reloadlist");
            this.$notify.success({ title: "Utfört" });
        },
    },

    computed: {
        title() {
            return this.form.id === 0 ? "Skapa kategorigrupp" : this.form.name;
        },
    },
};
</script>
